import { useAtom } from 'jotai';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import axios from '@/configs/axios.js';
import './voice-table.scoped.css';
import InfoIconRenderer from './ag-grid/InfoIconRenderer';
import FileNameRenderer from './ag-grid/FileNameRenderer';
import { callsToShowUserAtom } from '@/atoms/voice';
import { DocumentInfoModal } from '@/components/InfoModal/DocumentInfoModal';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable';

const defaultColDef = {
  resizable: true,
  sortable: true,
  filter: true,
  floatingFilter: true,
};

function VoiceTable() {
  const [callsToShowUser, setCallsToShowUser] = useAtom(callsToShowUserAtom);
  const [callsToShowUserByPhoneNum] = useState([]);
  const [callsToShowUserByFilter] = useState([]);
  const [chosenFile, setChosenFile] = useState('');
  const gridApi = useRef(null);

  const dateCellRenderer = (params) => {
    const date = new Date(params.value);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };
    return `${date.toLocaleDateString('en-GB', options)} `;
  };

  const [columnDefs] = useState([
    {
      headerName: 'מידע נוסף',
      field: '',
      filter: false,
      suppressMovable: true,
      width: 50,
      cellRendererSelector: () => {
        return {
          component: InfoIconRenderer,
          params: {
            handleChange: handleButtonClick,
          },
        };
      },
    },
    // {
    //   headerName: 'מספר נייד',
    //   field: 'phone_number',
    //   editable: true,
    //   filter: 'agTextColumnFilter',
    //   suppressMovable: true,

    //   cellClass: 'ag-cell-text-center',
    //   width: 50,
    // },
    {
      headerName: 'מדינה',
      field: 'country',
      editable: true,
      filter: 'agTextColumnFilter',
      suppressMovable: true,

      cellClass: 'ag-cell-text-right',
      width: 50,
    },
    {
      headerName: 'נושא הפניה',
      field: 'error',
      editable: true,
      filter: 'agTextColumnFilter',
      suppressMovable: true,
      cellClass: 'ag-cell-text-right',
      width: 80,
      cellStyle: { direction: 'rtl' },
    },
    {
      headerName: 'משך השיחה',
      field: 'duration',
      editable: true,
      suppressMovable: true,
      filter: 'agTextColumnFilter',
      cellClass: 'ag-cell-text-right',
      width: 40,
    },
    {
      headerName: 'מועד יצירה',
      field: 'created_at',
      editable: true,
      filter: 'agTextColumnFilter',
      sort: 'desc',
      initialSortIndex: 1,
      suppressMovable: true,
      cellClass: 'ag-cell-text-right',
      width: 80,
      cellRenderer: dateCellRenderer,
    },
    {
      headerName: 'שם קובץ ההקלטה',
      field: 'file_name',
      editable: true,
      filter: 'agTextColumnFilter',
      suppressMovable: true,
      cellClass: 'ag-cell-text-right',
      width: 70,
      cellRendererFramework: FileNameRenderer,
      cellRendererSelector: () => {
        return {
          component: FileNameRenderer,
        };
      },
    },
  ]);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: 'fitGridWidth',
    };
  }, []);

  const onGridReady = useCallback((params) => {
    window.addEventListener('resize', () => {
      setTimeout(() => {
        params.api.sizeColumnsToFit();
      });
    });
    gridApi.current = params.api;
  }, []);

  const headerRefresh = useCallback((params) => {
    params.api.refreshHeader();
  }, []);

  const handleButtonClick = (id) => {
    setChosenFile(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/voice/get-docs`);
        setCallsToShowUser(response.data);
      } catch (error) {
        console.error('Error fetching table data:', error);
      }
    };
    fetchData();
    return () => {};
  }, []);

  const mainFiles =
    callsToShowUserByPhoneNum.length > 0
      ? callsToShowUserByPhoneNum
      : callsToShowUser
      ? callsToShowUser
      : callsToShowUserByFilter
      ? callsToShowUserByFilter
      : [];

  const chosenDocument = mainFiles.filter((document) => document.id === chosenFile);

  return (
    <>
      <div className="ag-theme-alpine" style={{ width: '100%', height: '89vh', border: 'none' }}>
        {!callsToShowUser.length ? (
          <SkeletonTable />
        ) : (
          <AgGridReact
            columnDefs={columnDefs}
            rowData={callsToShowUser}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            paginationPageSize={20}
            onRowDataUpdated={headerRefresh}
          />
        )}
      </div>
      {chosenFile && (
        <DocumentInfoModal
          isOpen={true}
          header={`הקלטה #`}
          onClose={() => {
            setChosenFile('');
          }}
          document={chosenDocument[0]}
          duration={document.duration}
        />
      )}
    </>
  );
}

export default VoiceTable;
