import React, { useState } from 'react';
import './documents-table.scoped.css';
import pdfImg from '@/assets/pdf-img.png';
import wordImg from '@/assets/word-img.png';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import { ReactComponent as VectorLinkIcon } from '@/assets/vector-link-icon-lm.svg';
import moment from 'moment';
import { useAtom } from 'jotai';
import { documentsOpenPopUpAtom } from '@/atoms/documents';
import { documentsToShowUserAtom, markedDocumentsAtom } from '@/atoms/documents';
import { activeFolderAtom, userFoldersAtom, markedFoldersAtom } from '@/atoms/folders';
import { ReactComponent as FolderIcon } from '@/assets/folder-icon.svg';
import VectorModal from '../VectorModal/VectorModal';
import { treeAtom, entireTreeSizeAtom, allMarkedInTreeAtom, currentTreeSizeAtom } from '@/atoms/folders';
import { handleToggleFolder } from '@/services/mark/toggleMarkFolder';
import { toggleFileMark, toggleMarkAllTree } from '@/services/mark/toggleMarkFile';

function DocumentsTable({ handleFolderPress, documentsToShowUser, folders }) {
  const [activeFolder] = useAtom(activeFolderAtom);
  const [markedFolders] = useAtom(markedFoldersAtom);
  const [isMarkAll, setIsMarkAll] = useState(false);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  // const [allMarkedInTree] = useAtom(allMarkedInTreeAtom);
  const [, setDocumentsOpenPopUp] = useAtom(documentsOpenPopUpAtom);
  const [entireTree, setEntireTree] = useAtom(treeAtom);
  // const [entireTreeSize] = useAtom(entireTreeSizeAtom);
  const [currentTreeSize] = useAtom(currentTreeSizeAtom);
  const [openVectorPopUp, setOpenVectorPopUp] = useState({
    docId: '',
    method: '',
  });

  console.log(documentsToShowUser);
  console.log(openVectorPopUp.method);

  const isActiveFileCheckbox = (docId) => {
    return markedDocuments.filter((file) => file.docId === docId).length > 0;
  };
  const isActiveFolderCheckbox = (folderId) => {
    return markedFolders.filter((folder) => folder.id === folderId).length > 0;
  };
  const isValidJSONArr = (str) => {
    try {
      const arr = JSON.parse(str);
      return Array.isArray(arr);
    } catch (e) {
      return false;
    }
  };
  const handleMarkFolderChange = (e, folderId) => {
    e.stopPropagation();
    const [markedDocumentsResponse, entireTreeResponse] = handleToggleFolder(
      [...markedDocuments],
      folderId,
      [...entireTree.subfolders]
    );
    setEntireTree((prev) => ({ ...prev, subfolders: entireTreeResponse }));
    //  setMarkedDocuments(markedDocumentsResponse)
  };

  console.log('markedDocuments:', markedDocuments.length);
  console.log('current-tree-size:', currentTreeSize);

  const handleMarkAllChange = (event) => {
    if (activeFolder != '') {
      handleMarkFolderChange(event, activeFolder.id);
    } else {
      event.preventDefault();
      const newTree = toggleMarkAllTree(entireTree, !isMarkAll);
      setIsMarkAll(!isMarkAll);
      setEntireTree(newTree);
    }
  };

  const handleMarkChange = (
    event,
    docId,
    docTitle,
    docType,
    docDescription,
    vectorMehods,
    docNameOrigin
  ) => {
    event.preventDefault();

    const newTree = toggleFileMark(entireTree.files, entireTree.subfolders, docId);
    setEntireTree(newTree);
    // const markedDoc = markedDocuments.filter((item) => item.docId === docId)
    // if (markedDoc.length) {
    //   setMarkedDocuments(prev => prev.filter((item) => item.docId !== docId))
    // } else {
    //   setMarkedDocuments(prev => [...prev, { docId: docId, vectorMethods: vectorMehods, docDescription: docDescription, docTitle: docTitle, docType: docType, docNameOrigin: docNameOrigin }])
    // }
  };

  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th
                style={{
                  textAlign: 'left',
                  alignItems: 'center',
                  // display: 'flex',
                  // flexDirection: 'row',
                }}
              >
                <input type="checkbox" id={`checkboxId-all`} className="checkbox" />
                <div
                  onClick={handleMarkAllChange}
                  style={{ display: 'inline-block', verticalAlign: 'middle' }}
                >
                  {(markedDocuments.length === documentsToShowUser.length ||
                    markedDocuments.length === currentTreeSize) &&
                  markedDocuments.length !== 0 ? (
                    <CheckActiveIcon />
                  ) : (
                    <CheckIcon />
                  )}
                </div>
                <div style={{ marginLeft: '10px', display: 'inline-block', verticalAlign: 'middle' }}>
                  {' '}
                  ({markedDocuments.length}){' '}
                </div>
              </th>

              <th style={{ textAlign: 'left' }}>Name</th>
              <th>Owner </th>
              <th>Last Update</th>
              <th>Files Size </th>
            </tr>
          </thead>

          <tbody>
            {folders &&
              folders.length > 0 &&
              folders.map((folder, index) => {
                return (
                  <tr onClick={(e) => handleFolderPress(e, folder.id, folder.folder_name)} key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id={folder.id}
                        onClick={(e) => handleMarkFolderChange(e, folder.id)}
                        className="checkbox"
                      />
                      <label
                        htmlFor={folder.id}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {isActiveFolderCheckbox(folder.id) ? <CheckActiveIcon /> : <CheckIcon />}
                      </label>
                    </td>
                    <td style={{ textAlign: 'left' }} key={index}>
                      <div className="icon-name-container">
                        {' '}
                        <FolderIcon /> {folder.folder_name}
                      </div>{' '}
                    </td>
                    <td style={{ textAlign: 'center' }}>Me </td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(folder.updated_at)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      {/* {Math.round(folder.doc_size / 1000)} kb */}
                    </td>
                    {/* <td style={{textAlign:'right'}}> 
<input type='checkbox' id={document.doc_id} onChange={(e)=>handleChange(e,document.doc_id)} className='checkbox' />
<label for={document.doc_id}>
{isActiveCheckbox(document.doc_id) ?<CheckActiveIcon/>  : <CheckIcon/> }    
</label>
</td> */}
                  </tr>
                );
              })}
            {documentsToShowUser.length > 0 &&
              documentsToShowUser.map((document, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id={document.doc_id}
                        onClick={(e) =>
                          handleMarkChange(
                            e,
                            document.doc_id,
                            document.doc_title,
                            document.doc_type,
                            document.doc_description,
                            document.vector_methods,
                            document.doc_name_origin
                          )
                        }
                        className="checkbox"
                      />
                      <label htmlFor={document.doc_id}>
                        {isActiveFileCheckbox(document.doc_id) ? <CheckActiveIcon /> : <CheckIcon />}
                      </label>
                    </td>
                    <td style={{ textAlign: 'left' }} key={index}>
                      <div className="icon-name-container">
                        <img src={document.doc_type.trim() === 'pdf' ? pdfImg : wordImg} />

                        {document.doc_title}
                      </div>
                      {isValidJSONArr(document.vector_methods) && isActiveFileCheckbox(document.doc_id) && (
                        <div className="flex-vector-methods">
                          {JSON.parse(document.vector_methods).map((method) => {
                            return (
                              <button
                                className="vector-modal-btn"
                                onClick={() =>
                                  setOpenVectorPopUp({ docId: document.doc_id, method: method })
                                }
                              >
                                {' '}
                                <VectorLinkIcon /> {method}{' '}
                              </button>
                            );
                          })}
                        </div>
                      )}
                      {isActiveFileCheckbox(document.doc_id) && (
                        <div className="flex-vector-methods">
                          <button
                            className="vector-modal-btn"
                            onClick={() =>
                              setOpenVectorPopUp({ docId: document.doc_id, method: 'questions' })
                            }
                          >
                            {' '}
                            <VectorLinkIcon /> {'Questions'}{' '}
                          </button>
                        </div>
                      )}
                    </td>
                    <td style={{ textAlign: 'center' }}>Me </td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(document.created_at)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>
                    <td style={{ textAlign: 'center' }}>{Math.round(document.doc_size / 1000)} kb</td>
                    {/* <td style={{textAlign:'right'}}> 

            <input type='checkbox' id={document.doc_id} onChange={(e)=>handleChange(e,document.doc_id)} className='checkbox' />
            <label for={document.doc_id}>
            {isActiveCheckbox(document.doc_id) ?<CheckActiveIcon/>  : <CheckIcon/> }    
                </label>
             </td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {openVectorPopUp.docId !== '' && (
        <VectorModal
          docId={openVectorPopUp.docId}
          type={openVectorPopUp.method}
          isOpen={openVectorPopUp.docId !== ''}
          subHeader={'you can edit your data'}
          onClose={() => setOpenVectorPopUp({ method: '', docId: '' })}
        />
      )}
    </>
  );
}

export default DocumentsTable;
