export async function logout () {
  try {
    sessionStorage.removeItem('user');
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.error('Error logging out:', error);
    throw error;
  } finally {
    if(window.location.pathname === '/') return
    window.location.replace('/');
  }
}
