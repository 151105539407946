import React, { useEffect, useState } from 'react';
import './create-bot-modal.scoped.css';
import { Modal } from '@/components/Modal/Modal';
import { TextField } from '@mui/material';
import { useAtom } from 'jotai';
import { botCreatorDataAtom } from '@/atoms/bots';
import DocsModal from '@/components/BotCreator/DocsModal/DocsModal';
import axios from '@/configs/axios.js';

export const CreateBotModal = ({
  onClose,
  isOpen,
  openBotCreator,
  setIsOpenBotCreator,
  getCharacterPrompt,
  getBotDefaultPrompts,
}) => {
  const [documentData, setDocumentData] = useAtom(botCreatorDataAtom);
  const [openDocsModal, setOpenDocsModal] = useState(false);
  const [chosenDocs, setChosenDocs] = useState([]);

  useEffect(() => {
    setDocumentData({ bot_name: '', description: '', bot_audience: '' });
  }, []);

  const checkIfDisabled = () => {
    if (documentData.bot_name.trim().length) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async () => {
    await getBotDefaultPrompts();
    if (documentData.description.trim().length && documentData.bot_audience.trim().length)
      await getCharacterPrompt(documentData.description.trim(), documentData.bot_audience.trim());

    onClose();
    setIsOpenBotCreator(true);
  };

  const handleClose = async () => {
    setOpenDocsModal(false);
  };

  const handleTextField = (e) => {
    setDocumentData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const inputsStyle = {
    style: { color: 'var(--text-clr)', opacity: '0.5' },
  };

  return (
    <>
      {openDocsModal ? (
        <DocsModal handleClose={handleClose} setChosenDocs={setChosenDocs} onClose={handleClose} />
      ) : (
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          header={'Add New Bot'}
          style={{ height: 'auto' }}
          subHeader={'create your own bot'}
        >
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            value={documentData.bot_name}
            variant="filled"
            label="bot name"
            name="bot_name"
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
            onChange={handleTextField}
          />
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            variant="filled"
            label="Description"
            name="description"
            value={documentData.description}
            multiline
            rows={3}
            onChange={handleTextField}
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
          />
          <TextField
            fullWidth
            InputLabelProps={inputsStyle}
            variant="filled"
            label="Audience"
            name="bot_audience"
            value={documentData.bot_audience}
            onChange={handleTextField}
            InputProps={{
              disableUnderline: true, // Disables the underline
            }}
          />

          <div className="btns-container">
            <button className="submit-btn-document" onClick={() => setOpenDocsModal(true)}>
              + Documents
            </button>
            <button disabled={checkIfDisabled()} className="submit-btn-document" onClick={handleSubmit}>
              Create
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};
