import React from 'react';
import './prompts-table.scoped.css';
import promptImg from '@/assets/table-prompt-icon.png';
import { ReactComponent as CheckActiveIcon } from '@/assets/check-icon-active.svg';
import { ReactComponent as CheckIcon } from '@/assets/check-icon-lm.svg';
import {  markedPromptsAtom } from '@/atoms/prompts';
import moment from 'moment';
import { useAtom } from 'jotai';

function PromptTable({ handleFolderPress, promptsToShowUser }) {
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom);

  console.log(promptsToShowUser);

  const isActiveCheckbox = (prompt_id) => {
    return markedPrompts.filter((item) => item.prompt_id === prompt_id).length > 0;
  };

  const handleMarkChange = (event, prompt_id, title, description, content, isAdmin) => {
    event.preventDefault();
    const markedDoc = markedPrompts.filter((item) => item.prompt_id === prompt_id);
    if (markedDoc.length) {
      setMarkedPrompts((prev) => prev.filter((item) => item.prompt_id !== prompt_id));
    } else {
      setMarkedPrompts((prev) => [
        ...prev,
        {
          prompt_id: prompt_id,
          description: description,
          title: title,
          content: content,
          isAdmin: isAdmin,
        },
      ]);
    }
  };

  return (
    <>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'left' }}> ({markedPrompts.length}) </th>

              <th style={{ textAlign: 'left' }}>Name </th>
              <th>Owner </th>
              <th>Last Update</th>
              <th>Edit By</th>
            </tr>
          </thead>

          <tbody>
            {promptsToShowUser.length > 0 &&
              promptsToShowUser.map((prompt, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        id={prompt.prompt_id}
                        onClick={(e) =>
                          handleMarkChange(
                            e,
                            prompt.prompt_id,
                            prompt.title,
                            prompt.description,
                            prompt.content,
                            prompt.isAdmin
                          )
                        }
                        className="checkbox"
                      />
                      <label for={prompt.prompt_id}>
                        {isActiveCheckbox(prompt.prompt_id) ? <CheckActiveIcon /> : <CheckIcon />}
                      </label>
                    </td>
                    <td style={{ textAlign: 'left' }}>
                      <div className="icon-name-container">
                        <img src={promptImg} />

                        {prompt.title}
                      </div>
                    </td>
                    <td style={{ textAlign: 'center' }}>Me</td>
                    <td style={{ textAlign: 'center', direction: 'ltr' }}>
                      {moment(prompt.updated_At)
                        .locale('en')
                        .format('D MMM, YYYY')}
                    </td>

                    <td style={{ textAlign: 'center' }}>{prompt.changed_by && 'Me'}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {/* {openVectorPopUp.prompt_id !== '' && (
        <VectorModal
          header={'Questions & answers'}
          prompt_id={openVectorPopUp.prompt_id}
          type={openVectorPopUp.method}
          isOpen={openVectorPopUp.prompt_id !== ''}
          subHeader={'you can edit your data'}
          onClose={() => setOpenVectorPopUp({ method: '', prompt_id: '' })}
        />
      )} */}
    </>
  );
}

export default PromptTable;
