import React, { forwardRef } from 'react';
import { SnackbarContent } from '@mui/material';
import { ReactComponent as SuccessIcon } from '@/assets/success-icon-lm.svg';
import { ReactComponent as ErrorIcon } from '@/assets/error-icon-lm.svg';

const Snackbar = React.forwardRef((props, ref) => {
  const { message ,type} = props;
  let style;
  if (type ==='success'){
    style={
      backgroundColor: 'var(--green-clr)',
      color: 'var(--dark-green-clr)',
      display: 'flex',
      alignItems: 'center',
      fontSize:'16px',
      padding: '16px 16px',
      gap:'5px',
      borderRadius: '8px',
    };
  }else{
    style = {
      backgroundColor: '#FDEDED',
      color: '#DA5555',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize:'16px',
      padding: '16px 16px',
      boxShadow:'0px 0px 8px 0px rgba(0, 0, 0, 0.1)',
      gap:'10px',
      borderRadius: '8px',
      maxWidth: '410px',
    }
  }

  return (
    <div ref={ref} style={style}>
      <div>
      {type ==='success' ? <SuccessIcon style={{cursor:'auto'}} />  : <ErrorIcon style={{cursor:'auto'}} />}
      </div>
     {message}
      </div>
      // <SnackbarContent  style={style}>
    
      // </SnackbarContent>
  );
});

export default Snackbar;