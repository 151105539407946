import { atom } from 'jotai';
import axios from '@/configs/axios.js';
import { useAtom } from 'jotai';
import { promptsToShowUserAtom, activeFolderAtom, userFoldersAtom } from '@/atoms/prompts';
import { userAtom, loadingAtom, errorAtom } from '@/atoms/general';

export const getPromptsAndFoldersAtom = atom(null, async (get, set, val) => {
  const user = get(userAtom);
  const activeFolder = get(activeFolderAtom);
  console.log(activeFolder);
  console.log(user);

  try {
    let { data } = await axios.get(`/api/prompts/get-prompts-and-folders/${user.id}`, {
      params: {
        istopfolder: activeFolder ? false : true,
        folderid: activeFolder ? activeFolder.id : null,
      },
    });
    console.log(data);
    set(promptsToShowUserAtom, data.prompts);
    set(userFoldersAtom, data.subFolders);
  } catch (err) {
    console.log(err);
  }
});

export const getDefaultPromptsData = async () => {
  try {
    const response = await axios.get(`/api/prompts/default-prompts`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};
