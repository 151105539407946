import './register.scss';
import { useMemo, useState } from 'react';
import axios from '@/configs/axios.js';
import { propertiesAtom } from '@/atoms/general';
import { useAtom } from 'jotai';
// import { ReactComponent as Logo } from '@/assets/login-logo.svg';
// import app from '@/configs/firebase';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as FullCheckBox } from '@/assets/full-check-box.svg';
import { ReactComponent as EmptyCheckBox } from '@/assets/empty-check-box.svg';
import { useHistory } from 'react-router-dom';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { validateEmail } from '@/utils/regex';
import generateUniqueUserId from '@/utils/generateUniqueUserId.js';

const auth = getAuth();

export const Register = () => {
  const [properties] = useAtom(propertiesAtom);
  const [logoUrl, setLogoUrl] = useState('');
  const [fieldError, setFieldError] = useState('');
  const navigate = useHistory();
  const [registerData, setRegisterData] = useState({
    name: '',
    email: '',
    password: '',
    passwordAgain: '',
    termsCheckbox: false,
    company: '',
    model: JSON.stringify(['gpt-3.5-turbo-16k']),
    tokenLimit: 300000,
    groupId: 1,
  });

  useMemo(() => {
    setLogoUrl(properties.urls?.logo_urls['login-logo']);
  }, [properties]);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setRegisterData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRegister = async (ev) => {
    if (ev) ev.preventDefault();
    const {
      name,
      email,
      password,
      passwordAgain,
      termsCheckbox,
      company,
      groupId,
      model,
      tokenLimit,
    } = registerData;

    if (!name || !email || !password || !passwordAgain || !company) {
      alert('Make sure all fields are filled');
      return;
    }

    // if (password !== passwordAgain) {
    //   alert('Make sure all fields are filled');
    //   return;
    // }

    // var regex = /^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/;
    // let isValidPhoneNumber = regex.test(phoneNumber);

    // if (!isValidPhoneNumber) {
    //   var regexEnglish = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    //   isValidPhoneNumber = regexEnglish.test(phoneNumber);
    // }

    // if (!isValidPhoneNumber) {
    //   setRegisterData({ ...registerData, phoneNumber: '' });
    //   setFieldError('phoneNumber');
    //   return;
    // }

    var re = /^(?=.*[A-Za-z\u0590-\u05fe])(?=.*\d)[A-Za-z\u0590-\u05fe\d]{6,}$/;
    const isValidPassword = re.test(password);
    if (!isValidPassword) {
      setRegisterData({ ...registerData, password: '', passwordAgain: '' });
      setFieldError('password');
      return;
    }
    if (!termsCheckbox) {
      setFieldError('terms');
      return;
    }

    if (password !== passwordAgain) {
      setRegisterData({ ...registerData, password: '', passwordAgain: '' });
      setFieldError('passwordAgain');
      return;
    }
    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      setFieldError('emailInValid');
      return;
    }

    try {
      const userId = generateUniqueUserId();
      await createUserWithEmailAndPassword(auth, email, password);
      var { data } = await axios.post('/api/user/register', {
        email: email,
        name: name,
        lastConnected: Date.now(),
        timesConnected: 1,
        id: userId,
        company: company,
        tokenUsed: 0,
        tokenLimit: tokenLimit,
        model: model,
        groupId: groupId,
        wordsUsed: 0,
      });
      const { token } = data;
      sessionStorage.setItem('tokenForGeko', token);
      navigate.push('/login');
    } catch (error) {
      console.log(error);
      if (error.response.data === 'Firebase: Error (auth/email-already-in-use).') {
        setFieldError('emailInUse');
        setRegisterData({ ...registerData, email: '' });
        return;
      }
      alert('Error please try again later');
      return;
    }
  };

  const isDisbaled =
    !registerData.email ||
    !registerData.password ||
    !registerData.passwordAgain ||
    !registerData.company ||
    registerData.termsCheckbox === false;

  return (
    <div className="register-page">
      <div style={{ marginTop: '20px' }}>
        {/* <Logo /> */}
        {logoUrl ? (
          <img src={logoUrl} alt="onebi logo" />
        ) : (
          <div className="loading-icon">
            <CircularProgress
              sx={{
                position: 'absolute',
                left: '50%',
                color: properties?.colors?.main_colors['--main-clr'] || `rgba(249, 109, 223, 1)`,
                }}
                size='3rem'
            />
          </div>
        )}
      </div>

      <h1 className="main-title">Join our playground and create your AI bot</h1>
      <p style={{ marginBottom: 0 }} className="sub-title">
        We provide you with the tools and resources you need to create and customize your own AI bot.
      </p>
      <p className="sub-title">
        Let us help you unlock the potential of automation and bring your projects to life!
      </p>
      <div className="stages-container" />
      <div className="avatar-container">
        {/* <img src={UserImg} className="avatar-img" alt="user-image" /> */}

        <h6>Your details</h6>
        <p style={{ fontFamily: 'openSans-light' }}>Let’s start with your personal details</p>
      </div>
      <form onSubmit={handleRegister} className="register-form">
        <div className="input-container">
          <p>User name</p>
          <input
            autocomplete={false}
            value={registerData.name}
            name="name"
            onChange={handleChange}
            type="text"
          />
        </div>

        <div className="input-container">
          <p>Company</p>
          <input
            autocomplete={false}
            value={registerData.company}
            name="company"
            onChange={handleChange}
            type="text"
          />
        </div>

        <div className="input-container">
          <p>Email</p>
          <input
            autocomplete={false}
            value={registerData.email}
            name="email"
            onChange={handleChange}
            type="email"
            style={{
              marginBottom:
                fieldError === 'emailInValid' || fieldError === 'emailInUse' ? '4px' : '15px',
              borderColor:
                fieldError === 'emailInValid' || fieldError === 'emailInUse' ? 'red' : '#9e9e9e',
            }}
          />
          {fieldError === 'emailInValid' && <p className="error-input">Invalid email</p>}
          {fieldError === 'emailInUse' && (
            <p className="error-input">The email address is already in use</p>
          )}
        </div>

        <div className="input-container">
          <p>Password</p>
          <input
            autocomplete={false}
            value={registerData.password}
            name="password"
            onChange={handleChange}
            type="password"
            style={{
              marginBottom: fieldError === 'password' ? '4px' : '15px',
              borderColor: fieldError === 'password' ? 'red' : '#9e9e9e',
            }}
          />
          {fieldError === 'password' && (
            <p className="error-input">
              The password should be at least 6 characters long and contain numbers and letters
            </p>
          )}
        </div>

        <div className="input-container">
          <p>Password again</p>
          <input
            autocomplete={false}
            value={registerData.passwordAgain}
            name="passwordAgain"
            onChange={handleChange}
            type="password"
            className="input-test"
            style={{
              marginBottom: fieldError === 'passwordAgain' ? '4px' : '15px',
              borderColor: fieldError === 'passwordAgain' ? 'red' : '#9e9e9e',
            }}
          />
          {fieldError === 'passwordAgain' && (
            <p className="error-input">The passwords are not the same</p>
          )}
        </div>

        <div
          style={{
            marginBottom: fieldError === 'terms' ? '0px' : '14px',
            marginTop: '30px',
          }}
          className="checkbox-container"
        >
          <p>
            Confirm{' '}
            <a href="/policy" target="_blank" style={{ color: 'var(--main-clr)' }}>
              Terms of Use
            </a>
          </p>
          {registerData.termsCheckbox ? (
            <FullCheckBox
              onClick={() => {
                setRegisterData((prev) => ({
                  ...prev,
                  termsCheckbox: false,
                }));
              }}
              className="checkbox"
            />
          ) : (
            <EmptyCheckBox
              onClick={() => {
                setRegisterData((prev) => ({ ...prev, termsCheckbox: true }));
              }}
              className="checkbox"
            />
          )}
        </div>
        {fieldError === 'terms' && <p className="error-input">Confirm terms of use</p>}
      </form>

      <button onClick={handleRegister} className="register-btn" disabled={isDisbaled}>
        Next
      </button>
    </div>
  );
};
