import axios from 'axios'
import { logout } from '../actions/auth.action';

let axiosInstance = null

function createAxiosInstance() {
    if(axiosInstance) {
        return axiosInstance
    }
    console.warn('Creating axios instance')
    axiosInstance = axios.create({ withCredentials: true });
    const sessionJwtToken = sessionStorage.getItem('token');
    if (sessionJwtToken) {
        console.log('Setting axios token from session')
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${sessionJwtToken}`;
    }

    axiosInstance.updateAxiosToken = function (token) {
        console.warn('Updating axios token')
        sessionStorage.setItem('token', token);
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };
    axiosInstance.interceptors.response.use((response) => response, async (error) => {
        const url = error.config.url
        console.error(`Error in axios url: ${url} response: ${error}`)
        if(['/api/logs'].includes(url)) {
            console.warn(`Skipping logout 401 for ${url}`)
            return
        }
        if (error.response.status === 401) {
            try {
                logout()
            } catch (error) {
                console.log({ error })
            }
        }
      })
    return axiosInstance;
}

export default createAxiosInstance()
