import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
// import HttpsRedirect from 'react-https-redirect';
import { MainPage } from './pages/MainPage/MainPage';
import { WelcomePage } from './pages/WelcomePage/WelcomePage';
import { Login } from './pages/Login/Login';
import { Register } from './pages/Register/Register';
import { useCallback, useEffect } from 'react';
import { userAtom } from './atoms/general';
import { finishedLoginAtom } from '@/api/users';
import { refreshUserJwtToken } from '@/api/jwt.js';
import setColors from './hooks/setColors';
import { Provider } from 'jotai';
import { TurboPage } from './pages/TurboPage';
import { useAtom } from 'jotai';
import { socketAtom, propertiesAtom } from './atoms/general';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { fileUploadCallbackAtom, fileErrorCallbackAtom } from '@/atoms/files';
import { useSnackbar } from 'notistack';
import Snackbar from '@/components/Snackbar/Snackbar';
import { SnackbarProvider } from 'notistack';
import { getDocumentsAndFoldersAtom } from '@/api/documents';
import { getPromptsAndFoldersAtom } from '@/api/prompts';
import { getProperties } from '@/api/properties';
import { logout } from './actions/auth.action';
import { ACCESS_JWT_EXPIRATION, JWT_REFRESH_CHECK_INTERVAL } from './api/jwt';

function App() {
    const [socket] = useAtom(socketAtom);
    const [, setProperties] = useAtom(propertiesAtom);
    const [finishedLogin, setFinishedLogin] = useAtom(finishedLoginAtom);
    const [fileUploadCallback] = useAtom(fileUploadCallbackAtom);
    const [fileErrorCallback] = useAtom(fileErrorCallbackAtom);
    const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
    const [, getPromptsAndFolders] = useAtom(getPromptsAndFoldersAtom);
    const [, setToolKitSettings] = useAtom(toolKitSettingsAtom);
    const [user, setUser] = useAtom(userAtom);
    const { enqueueSnackbar } = useSnackbar();
    //   const auth = getAuth();
    const navigate = useHistory();

    // using dummy user to bypass authentication
    // initialize the user to empty if want to go to login screen
    const handleFileSuccessEvent = () => { };

    const handleFileErrorEvent = (error) => {
        if (fileErrorCallback) {
            enqueueSnackbar('We encountered a problem uploading, please try again', {
                content: (key, message) => <Snackbar type={'error'} message={message} />,
            });
        }
    };
    const refreshToken = useCallback(async () => {
        const now = new Date().getTime()
        const lastAuthTimestamp = sessionStorage.getItem('lastAuthTimestamp') || 0
        if (lastAuthTimestamp && now - lastAuthTimestamp < ACCESS_JWT_EXPIRATION - ACCESS_JWT_EXPIRATION / 10) {
            // console.log('JWT token not about to expire, skipping refresh')
            // JWT token not about to expire, skipping refresh
            setTimeout(refreshToken, JWT_REFRESH_CHECK_INTERVAL)
            if (!finishedLogin) {
                // Setting finished login for paeg refresh.
                setFinishedLogin(true)
            }
            return true
        }
        const refreshed = await refreshUserJwtToken()
        if (!refreshed) {
            setFinishedLogin(false)
            logout()
            return false
        }

        sessionStorage.setItem('lastAuthTimestamp', now)
        if (!finishedLogin) {
            setFinishedLogin(true)
            return false
        }

        setTimeout(refreshToken, JWT_REFRESH_CHECK_INTERVAL)


        return true
    }, [setFinishedLogin, finishedLogin]);

    // Handles jwt token refresh
    useEffect(() => {
        async function checkRefresh() {
            const sessionUser = user || sessionStorage.getItem('user')
            if (!sessionUser) {
                sessionStorage.removeItem('lastAuthTimestamp')
                sessionStorage.removeItem('user')
                return
            }
            setUser(sessionUser)
            const refreshed = await refreshToken()

            if (!refreshed) {
                setFinishedLogin(false)
                logout()
                return
            }
            console.log('Setting finished login')
            setFinishedLogin(true)
        }
        checkRefresh()
    }, [refreshToken, user, setFinishedLogin, setUser]);

    useEffect(() => {
        const setChatToolkit = (settings) => {
            setToolKitSettings({
                chatLang: settings['translate'].default_value,
                template: 'none',
                model: settings['model'].default_value,
                isMultiple: settings['answers'].default_value,
                isLongAnswer: settings['scope'].default_value,
                isTypingEffect: settings['typing'].default_value,
                temperature: settings['temperature'].default_value,
                question_choice: settings['data'].default_value
            });
        };
        const setSettings = async () => {
            setFinishedLogin(false)
            sessionStorage.removeItem('lastAuthTimestamp')
            const settings = await getProperties();
            await setColors(settings.colors);
            setProperties(settings);
            setChatToolkit(settings.toolkit)
        };
        setSettings();
    }, []);

    useEffect(() => {
        socket.on('file-uploaded-successfully', handleFileSuccessEvent);
        socket.on('file-upload-error', handleFileErrorEvent);

        return () => {
            socket.off('file-uploaded-successfully', handleFileSuccessEvent);
            socket.off('file-upload-error', handleFileErrorEvent);
        };
    }, [fileUploadCallback, fileErrorCallback]);

    useEffect(() => {
        if (!finishedLogin || !user) return
        // const publicRoutes =
        //   window.location.href.includes('/register') || window.location.href.includes('/turbo');
        // if (!user && !publicRoutes) {
        //   navigate.push('/');
        //   return;
        // }
        getDocumentsAndFolders();
        getPromptsAndFolders();

        return () => {
            socket.off('client-id');
        };
    }, [finishedLogin, socket, user]);

    return (
        <>
            <Route
                exact
                path="/"
                render={() => (
                    <WelcomePage
                    //  user={user}
                    //  setUser={setUser}
                    // socket={socket}
                    />
                )}
            />
            <Route
                exact
                path="/register"
                render={() => (
                    <Register
                    //  user={user}
                    //  setUser={setUser}
                    // socket={socket}
                    />
                )}
            />
            <Route
                exact
                path="/playground"
                render={() => (
                    <MainPage
                        //  user={user}
                        //  setUser={setUser}
                        socket={socket}
                    />
                )}
            />
            <Route path="/turbo" render={() => <TurboPage />} />
            <Route
                path="/login"
                render={() => (
                    <Login

                    // setUser={setUser}
                    />
                )}
            />
        </>
    );
    {
        /* <Route path="/welcome-page" render={() => <WelcomePage user={user} />} />
          <Route path="/history" render={() => <HistoryPage setUser={setUser} user={user} />} />
          <Route path="/turbo" render={() => <TurboPage />} />
          <Route path="/resource/:ids" render={() => <ResourcesPage />} />
          <Route path="/login/callback" component={LoginCallback} /> */
    }
}
const AppWithRouterAccess = () => (
    //   <HttpsRedirect>
    <Provider>
        <Router>
            <SnackbarProvider maxSnack={1}>
                <App />
            </SnackbarProvider>
        </Router>
    </Provider>
    //   </HttpsRedirect>
);

export default AppWithRouterAccess;
