import axios from '@/configs/axios.js';

export const  JWT_REFRESH_CHECK_INTERVAL = 5000
export const ACCESS_JWT_EXPIRATION = 300000

export const refreshUserJwtToken = async () => {
  try {
    console.log('Refreshing JWT token')
    const res = await axios.post('/api/v1/iam/playground/refresh-token')
    if(res.status !== 200) {
      throw new Error(`Error refreshing JWT token, got: ${res.status}`);
    }
    console.log('Updated axios token')
    axios.updateAxiosToken(res.data.token)
    return true
  } catch (error) {
    return false
  }
};
