import './welcomePage.scss';
import { Link } from 'react-router-dom';
import { useState, useMemo } from 'react';
// import { ReactComponent as Logo } from '@/assets/login-logo.svg';
// import { ReactComponent as CreditLogo } from '@/assets/credit-logo.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { propertiesAtom } from '@/atoms/general';
import { isMobileAtom } from '@/atoms/general';
import { useAtom } from 'jotai';

export const WelcomePage = () => {
  const [isMobile] = useAtom(isMobileAtom);
  const [properties] = useAtom(propertiesAtom);
  const [logoUrls, setLogoUrls] = useState({});

  useMemo(() => { 
    setLogoUrls({
      login_logo: properties.urls?.logo_urls['login-logo'],
      credit_logo: properties.urls?.logo_urls['credit-logo'],
    });
  }, [properties]);

  return (
    <div className="welcome-page">
      <div className="modal-full-screen">
        <div
          className="modal-container"
          style={isMobile ? { width: '90%', overflowY: 'visible' } : { width: '55%', overflowY: 'auto' }}
        >
          <div className="modal-upper">
            {logoUrls.login_logo ? (
              <img
                src={logoUrls.login_logo}
                alt="onebi logo"
                // style={isMobile ? { width: '180px' } : { width: '253px' }}
              />
            ) : (
              <div className="loading-icon-login">
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    left: '46%',
                    color: properties?.colors?.main_colors['--main-clr'] || `red`,
                  }}
                  size="50px"
                />
              </div>
            )}
          </div>
          <h2>Welcome Back</h2>
          <div className="form-container">
            <Link to="/register">
              <button className="register-btn">Register</button>
            </Link>
            <Link className="link-margin-20" to="/login">
              <button className="login-btn">Login</button>
            </Link>
          </div>
        </div>
        <div className="bottom-credit" style={isMobile ? { left: '30%' } : { left: '45%' }}>
          <h4>Created By</h4>
          {logoUrls.credit_logo ? (
            <img src={logoUrls.credit_logo} alt="onebi logo" />
          ) : (
            <CircularProgress
              sx={{
                position: 'absolute',
                left: '100%',
                bottom: '0%',
                color: properties?.colors?.main_colors['--main-clr'] || `rgba(249, 109, 223, 1)`,
              }}
              size="20px"
            />
          )}
        </div>
      </div>
    </div>
  );
};
