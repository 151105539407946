import React, { useState } from 'react'
import './attachment.scoped.css'
import { useAtom } from 'jotai'
import { handleFileAddAtom, uploadFile } from '@/atoms/logs'
import { ReactComponent as AttachmentIcon } from '@/assets/attachment-icon-lm.svg'
import { toolKitSettingsAtom } from '@/atoms/chat'
import { useSnackbar } from 'notistack'
import Snackbar from '@/components/Snackbar/Snackbar'
import CircularProgress from '@mui/material/CircularProgress';

const Attachment = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [toolKitSettings] = useAtom(toolKitSettingsAtom)
  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = React.createRef()
  const [, addFile] = useAtom(handleFileAddAtom)
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    const formData = new FormData();
    formData.append('file', file);
    formData.append('modelChosen', toolKitSettings.model);
    try {
      if (file) {
        const data = await uploadFile(formData);
        addFile({ key: id, file: file, modelChosen: toolKitSettings.model, id: data });
        enqueueSnackbar('The file has been uploaded', {
          content: (key, message) => <Snackbar type={'success'} message={message} />,
        });
        console.log('Selected file:', file.name);
        console.log('toolKitSettings.model', toolKitSettings.model);
      }
    } catch (error) {
        if (error.message === 'Unsupported file format') {
          enqueueSnackbar('File type is not supported', {
            content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        } else {
          enqueueSnackbar('Oops, something went wrong. Please try again.', {
            content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <button onClick={handleButtonClick}>
      {isLoading ? <CircularProgress size={24} style={{ color: "rgba(249, 109, 223, 1)" }}/>:  <AttachmentIcon /> }
      </button>
      <input
        type="file"
        ref={fileInputRef}
        disabled={isLoading}
        accept=".doc, .docx, .pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }} // Hide the file input
      />
    </>
  )
}

export default Attachment