export const promptsSettingsData = {
  characterPrompt: {
    isOpen: true,
    title: 'Character Prompt',
    subTitle: 'Defines the response nature for a non-data response',
    model: 'GPT-4o',
    is_selected: true,
    inputConversationLanguage: 'To English',
    content: ``,
  },
  analysisPrompt: {
    isOpen: false,
    title: 'Analysis Prompt',
    isConnectToDocs: true,
    subTitle:
      'Receives the entire conversation with the user and returns the current request in one sentence',
    model: 'GPT-4o',
    is_selected: false,
    content: ``,
  },
  gradePrompt: {
    isOpen: false,
    title: 'Grade Prompt',
    isConnectToDocs: true,
    is_selected: false,
    subTitle:
      'Receives the vectors (the text chunks) and gives a score to each one according to their suitability',
    model: 'GPT-4o',
    inputConversationLanguage: 'Original language',
    content: ``,
    vectors: {
      vectorsNumber: 5,
      passingGrade: 60,
    },
  },
  relevantPrompt: {
    isOpen: false,
    is_selected: true,
    is_must: true,
    title: 'Relevant Answer Prompt',
    isConnectToDocs: true,
    subTitle:
      'Receives the vector data and the conversation with the user, formulates an optimal answer and returns it to the user',
    model: 'GPT-4o',
    inputConversationLanguage: 'Original language',
    inputVectorsLanguage: 'Original language',
    content: ``,
    isAnswerBasedOnBestGrade: true,
    messageToUser: {
      titleForFront: 'No Relevant Message - optional',
      content: '',
      placeHolder:
        'Message example: Sorry, I Did not find an answer to the question. You can also add an hyperlink by adding {the word that will link} <the url>    example: {OneBi website} <https://onebi.co.il/> ',
    },
  },
  hackPrompt: {
    isOpen: false,
    is_selected: false,
    title: 'Hack Prompt',
    subTitle: 'Accepts the user transport and checks if it is related to the bot theme',
    model: 'GPT-4o',
    content: ``,
    messageToUser: {
      titleForFront: 'Hack Message - optional ',
      content: '',
      placeHolder:
        'Message example: Sorry, I can not find answer to this question, Please ask something relevant to the subject. You can also add an hyperlink by adding {the word that will link} <the url>    example: {OneBi website} <https://onebi.co.il/> ',
    },
  },
}
