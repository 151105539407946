import './login.scoped.scss';
import React, { useMemo } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
// import { ReactComponent as LoginLogo } from '@/assets/login-logo.svg';
// import { ReactComponent as CreditLogo } from '@/assets/credit-logo.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userAtom, propertiesAtom } from '@/atoms/general';
import { isMobileAtom } from '@/atoms/general';
import { useAtom } from 'jotai';
import axios from '@/configs/axios.js';
import { finishedLoginAtom } from '@/api/users';
import { getDocumentsAndFoldersAtom } from '@/api/documents';

const auth = getAuth();

export const Login = () => {
  const [user, setUser] = useAtom(userAtom);
  const [finishedLogin, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [properties] = useAtom(propertiesAtom);
  const [loginForm, setLoginForm] = useState({ email: '', password: '' });
  // const [logoUrl, setLogoUrl] = useState('');
  const [logoUrls, setLogoUrls] = useState({});

  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLoginForm((prev) => ({ ...prev, [name]: value }));
  };
  // const { oktaAuth, authState } = useOktaAuth()
  const navigate = useHistory();

  useMemo(() => { 
    setLogoUrls({
      login_logo: properties.urls?.logo_urls['login-logo'],
      credit_logo: properties.urls?.logo_urls['credit-logo'],
    });
  }, [properties]);

  const handleLogin = async (e) => {
    e.preventDefault();
    let fireBaseResponse;
    let emailCheckResponse;
    /// add client side validation
    try {
      fireBaseResponse = await signInWithEmailAndPassword(auth, loginForm.email, loginForm.password);
      const fbUser = fireBaseResponse.user;
      emailCheckResponse = await axios.put('/api/user/login', {
        email: fbUser.email,
      });
      const { user, token } = emailCheckResponse.data;
      setUser({
        id: user.id,
        email: fbUser.email,
        userName: user.name,
        lang: 'english',
      });
      sessionStorage.setItem('lastAuthTimestamp', new Date().getTime());
      axios.updateAxiosToken(token);
      setFinishedLogin(true);
    } catch (err) {
      alert('username or password are invalid');
      console.log(err.message);
    }
  };
  if (finishedLogin) {
    navigate.push('/playground');
    getDocumentsAndFolders();
    // return <Redirect push to='/playground' />
  }

  return (
    <div className="login-page">
      <div className="modal-full-screen">
        <div
          className="modal-container"
          style={isMobile ? { width: '90%', overflowY: 'visible' } : { width: '55%', overflowY: 'auto' }}
        >
          <div className="modal-upper">
            {/* <LoginLogo style={isMobile ? { width: '180px' } : { width: '253px' }} /> */}
            {logoUrls.login_logo ? (
              <img
                src={logoUrls.login_logo}
                alt="onebi logo"
                style={isMobile ? { width: '180px' } : { width: '253px' }}
              />
            ) : (
              <div className="loading-icon-login">
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    left: '46%',
                    color: properties?.colors?.main_colors['--main-clr'] || `red`,
                  }}
                  size="50px"
                />
              </div>
            )}
          </div>
          <div style={isMobile ? { width: '300px' } : { width: 'auto' }}>
            <h2>Jeen.AI Your AI environment</h2>
          </div>

          <div className="form-container">
            <form onSubmit={handleLogin}>
              <input
                value={loginForm.email}
                style={isMobile ? { width: '230px' } : { width: '490px' }}
                name="email"
                onChange={handleChange}
                type="text"
                placeholder="email"
              />
              <input
                value={loginForm.password}
                style={isMobile ? { width: '230px' } : { width: '490px' }}
                name="password"
                onChange={handleChange}
                type="password"
                placeholder="password"
              />
              <button
                onClick={handleLogin}
                className="login-btn"
                disabled={loginForm.email === '' || loginForm.password === ''}
              >
                Login
              </button>
            </form>
          </div>
        </div>
        <div className="bottom-credit" style={isMobile ? { left: '30%' } : { left: '45%' }}>
          <h4>Created By</h4>
          {/* <CreditLogo /> */}
          {logoUrls.credit_logo ? (
            <img src={logoUrls.credit_logo} alt="onebi logo" />
          ) : (
            <div className="loading-icon-credit">
              <CircularProgress
                sx={{
                  position: 'absolute',
                  left: '100%',
                  bottom: '0%',
                  color: properties?.colors?.main_colors['--main-clr'] || `rgba(249, 109, 223, 1)`,
                }}
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
