import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import './SkeletonTable.css';

function SkeletonTable() {
  const skeletonRows = Array.from({ length: 9 });

  return (
    <table className="skeleton-table">
      <thead>
        <tr>
          <th><Skeleton animation={"wave"} variant="circular" width={50} height={50} style={{marginLeft:'10px'}} /></th>
          <th><Skeleton animation={"wave"} variant="text" width={120} height={40} /></th>
          <th><Skeleton animation={"wave"} variant="text" width={100} height={40} /></th>
          <th><Skeleton animation={"wave"} variant="text" width={80} height={40} /></th>
        </tr>
      </thead>
      <tbody>
        {skeletonRows.map((_, index) => (
          <tr key={index} className="skeleton-row">
            <td><Skeleton animation={"wave"} variant="circular" height={50} width={50} /></td>
            <td><Skeleton animation={"wave"} variant="text" height={40} width="80%" /></td>
            <td><Skeleton animation={"wave"} variant="text" height={40} width="60%" /></td>
            <td><Skeleton animation={"wave"} variant="text" height={40} width="40%" /></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default SkeletonTable;
