import React, { useMemo, useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import { Modal } from '@/components/Modal/Modal';
import ConversionTable from '../ConversionTable/ConversionTable.jsx';
import { ReactComponent as AddNewIcon } from '@/assets/add_new_conversions.svg';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import moment from 'moment';
import './conversion-modal.scoped.css';
import {
  translatesDataAtom,
  filteredTranslatesAtom,
  updateTranslatesAtom,
  searchAtom,
} from '@/atoms/translates.js';
import {
  createTranslates,
  getTranslates,
  deleteTranslates,
  updateTranslates,
} from '@/api/translates';
import { Height } from '@mui/icons-material';

function ConversionModal({ onClose, handleClose, botId }) {
  const [search, setSearch] = useAtom(searchAtom);
  const [, setTranslatesData] = useAtom(translatesDataAtom);
  const [, setTranslates] = useAtom(updateTranslatesAtom);
  const [translates] = useAtom(filteredTranslatesAtom);
  const [initialTranslates, setInitialTranslates] = useState([]);

  useEffect(() => {
    const fetchTranslates = async () => {
      const result = await getTranslates(botId);

      setTranslatesData(result);
      setInitialTranslates(result);
    };
    fetchTranslates();
  }, []);

  const differences = useMemo(() => {
    const added = translates.filter(
      (newObj) => !initialTranslates.some((oldObj) => oldObj.id === newObj.id)
    );
    const removed = initialTranslates.filter(
      (oldObj) => !translates.some((newObj) => newObj.id === oldObj.id)
    );
    const modified = translates.filter((newObj) => {
      const original = initialTranslates.find((oldObj) => oldObj.id === newObj.id);
      return original && !isEqual(original, newObj);
    });

    return { added, removed, modified };
  }, [translates, initialTranslates]);

  console.log(differences);

  const isDisabled =
    differences.added.length === 0 &&
    differences.removed.length === 0 &&
    differences.modified.length === 0;

  const handleSubmit = () => {
    const { added, removed, modified } = differences;

    if (added.length > 0) {
      createTranslates(added);
    }
    if (removed.length > 0) {
      deleteTranslates(removed);
    }
    if (modified.length > 0) {
      updateTranslates({
        translates: modified,
        date: moment().format('D MMM, YYYY'),
      });
    }
    if (added.length === 0 && removed.length === 0 && modified.length === 0) {
      console.log('No changes have been made to the conversion table!');
    }

    handleClose();
  };

  const handleNewRow = () => {
    const newTranslate = {
      id: uuidv4(),
      bot_id: botId,
      src: '',
      translated: '',
      type: 'input',
      last_updated: moment().format('D MMM, YYYY'),
      is_active: true,
    };
    setTranslates({
      type: 'createTranslate',
      payload: { val: newTranslate },
    });
  };

  const settingsArr = [
    {
      name: 'type',
      options: [
        { label: 'Input', value: 'input' },
        { label: 'Output', value: 'output' },
        { label: 'Input + Output', value: 'input+output' },
      ],
    },
    {
      name: 'headers',
      options: [
        {
          style: {
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          },
          type: 'component',
        },
        {
          value: 'Conversion Type',
          style: { textAlign: 'left' },
          type: 'header',
        },
        {
          value: 'Original Text',
          style: { textAlign: 'left' },
          type: 'header',
        },
        { value: 'Convert Text', style: { textAlign: 'left' }, type: 'header' },
        {
          value: 'Last Update',
          style: { textAlign: 'center' },
          type: 'header',
        },
      ],
    },
  ];

  return (
    <Modal
      onClose={onClose}
      isOpen={true}
      style={{ width: '80%', gap: '15px', maxWidth: '1300px', height: '712px' }}
    >
      <div className="header">
        <span>Conversion Table</span>
      </div>
      <div className="search-box-container">
        <input
          id={'1'}
          value={search}
          className="search-input"
          placeholder="Search"
          type="tel"
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <ConversionTable translates={translates} settings={settingsArr} botId={botId} />
      <div
        className="add-new-button"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '105px',
          marginRight: 'auto',
          marginLeft: '15px',
        }}
      >
        <AddNewIcon onClick={handleNewRow} />
        <div>{'Add New'}</div>
      </div>
      <button disabled={isDisabled} className="submit-btn-document" type="submit" onClick={handleSubmit}>
        Submit
      </button>
    </Modal>
  );
}

export default ConversionModal;
