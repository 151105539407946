
import { atom } from "jotai"
import { atomWithStorage } from 'jotai/utils';

export const toolKitSettingsAtom = atomWithStorage('toolkit',{
    chatLang:'none',
    template:'none',
    model:'gpt-4o',
    isMultiple:false,
    isLongAnswer:false,
    isTypingEffect:false,
    temperature:0,
    question_choice:['Vectors','Q&A']
})