import axios from '@/configs/axios.js';
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import './botCreator.scoped.scss';
import { ReactComponent as ExpandIcon } from '@/assets/bot-creator-prompt-expand.svg';
import { ReactComponent as CollapseIcon } from '@/assets/bot-creator-prompt-collapse.svg';
import { ReactComponent as BackBtn } from '@/assets/back-btn.svg';
import { ReactComponent as DisableSelectBotName } from '@/assets/bot-select-disabled.svg';
import { ReactComponent as TimeDurationIcon } from '@/assets/time-duration-icon.svg';
import { ReactComponent as ScopeIcon } from '@/assets/scope-icon-lm.svg';
import { ReactComponent as PlusIcon } from '@/assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from '@/assets/minus-icon.svg';
import { ReactComponent as OptionSelected } from '@/assets/check-icon-active.svg';
import { ReactComponent as OptionUnSelected } from '@/assets/check-icon-lm.svg';
import { PromptContainer } from '@/components/BotCreator/PromptContainer';
import { promptsSettingsData } from './promptsData';
import { userAtom } from '@/atoms/general';
import DocsModal from '@/components/BotCreator/DocsModal/DocsModal';
import ConversionModal from '@/components/BotCreator/Conversion/ConversionModal/ConversionModal';
import { pathAtom, activeFolderAtom, treeAtom } from '@/atoms/folders';
import { botCreatorDataAtom, toolKitSettingsAtom } from '@/atoms/bots';
import { markedDocumentsAtom } from '@/atoms/documents';
import { setFilesMark } from '@/services/mark/toggleMarkFile';
import { createOrUpdateBot } from '@/api/bots';
import { useSnackbar } from 'notistack'
import Snackbar from '../../components/Snackbar/Snackbar';

const BotManage = ({ botId, prompts, setIsOpenBotCreator, getAllBotsUser }) => {
  if (prompts.characterPrompt) {
    promptsSettingsData.characterPrompt.content = prompts.characterPrompt;
  }
  if (prompts.analysisPrompt) {
    promptsSettingsData.analysisPrompt.content = prompts.analysisPrompt.content;
  }
  if (prompts.gradePrompt) {
    promptsSettingsData.gradePrompt.content = prompts.gradePrompt.content;
  }
  if (prompts.hackPrompt) {
    promptsSettingsData.hackPrompt.content = prompts.hackPrompt.content;
  }
  if (prompts.relevantPrompt) {
    promptsSettingsData.relevantPrompt.content = prompts.relevantPrompt.content;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [, setActiveFolder] = useAtom(activeFolderAtom);
  const [, setPath] = useAtom(pathAtom);
  const [openDocsModal, setOpenDocsModal] = useState(false);
  const [openConversionModal, setOpenConversionModal] = useState(false);
  const [user] = useAtom(userAtom);
  const [chosenDocs, setChosenDocs] = useState([]);
  const [firstMessageValue, setFirstMessageValue] = useState('היי, איך אוכל לעזור לך היום? ');
  const [firstMessageIsOpen, setFirstMessageIsOpen] = useState(true);
  const [toolKitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [, setIsEditing] = useState(false);
  const [botCreatorData, setBotCreatorData] = useAtom(botCreatorDataAtom);
  const [entireTree, setEntireTree] = useAtom(treeAtom);
  const [promptsSettings, setPromptsSettings] = useState({
    ...promptsSettingsData,
  });
  const [endMessageValue, setEndMessageValue] = useState({
    isOpen: false,
    is_selected: false,
    content: '',
    isMessageLimitation: true,
    isTimeout: false,
    maxLength: 5,
    timeLimited: 4,
  });
  const [errorMessageValue, setErrorMessageValue] = useState({
    isOpen: false,
    is_selected: false,
    content: '',
    default_message: 'Please try to refresh or ask the question again',
  });
  const [additionalLinksTitle, setAdditionalLinksTitle] = useState({
    isOpen: false,
    is_selected: false,
    content: '',
  });

  console.log('botId:', botId);

  useEffect(() => {
    if (toolKitSettings && !toolKitSettings.question_choice) {
      // Clear local storage keys
      sessionStorage.removeItem('user');
      localStorage.removeItem('botCreatorToolkit');
      localStorage.removeItem('user');

      // Refresh the page
      window.location.replace('/');
    }

    if (botId) {
      getBotData();
    } else {
      setToolKitSettings({
        is_active: 'No',
        status: 'Development',
        should_bot_translate: 'None',
        ui_language: 'English (UK)',
        questions_selected: [],
        validations_selected: [],
        question_choice: [],
      });
      setIsDoneLoading(true);
    }
  }, [botId]);

  const getBotData = async () => {
    const botData = await axios.get(`/api/bots/bot-configuration/${botId}`);
    setPromptsSettings({ ...botData.data.promptsSettings });
    setToolKitSettings({ ...botData.data.toolKitSettings });
    setFirstMessageValue(botData.data.firstMessageValue);
    setEndMessageValue({ ...botData.data.endMessageValue });
    setErrorMessageValue({
      ...botData.data.errorMessageValue,
    });
    setAdditionalLinksTitle({ ...botData.data.additionalLinksTitle });
    setBotCreatorData((prev) => ({ ...prev, bot_name: botData.data.bot_name }));
    setIsDoneLoading(true);

    const newTree = setFilesMark(entireTree.files, entireTree.subfolders, [...botData.data.chosenDocs]);
    setChosenDocs([...botData.data.chosenDocs]);
    setEntireTree(newTree);
  };

  const updatePromptSetting = (prompt, key, value) => {
    setPromptsSettings((prevSettings) => ({
      ...prevSettings,
      [prompt]: {
        ...prevSettings[prompt],
        [key]: value,
      },
    }));
  };

  const handleFirstMessageChange = (e) => {
    const { value } = e.target;
    setFirstMessageValue(value);
  };

  const handleEndMessageChange = (e) => {
    const { value } = e.target;
    setEndMessageValue((prev) => ({ ...prev, content: value }));
  };

  const handleErrorMessageChange = (e) => {
    const { value } = e.target;
    setErrorMessageValue((prev) => ({ ...prev, content: value }));
  };

  const handleAdditionalLinksSelect = () => {
    if (toolKitSettings.questions_selected.includes('Web page link')) {
      setAdditionalLinksTitle((prev) => ({ ...prev, is_selected: !prev.is_selected }));
    }
  };

  const handleAdditionalLinksOpen = () => {
    if (toolKitSettings.questions_selected.includes('Web page link')) {
      setAdditionalLinksTitle((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    }
  };

  const handleAdditionalLinksTitleChange = (e) => {
    const { value } = e.target;
    setAdditionalLinksTitle((prev) => ({ ...prev, content: value }));
  };

  const handleBotNameChange = (e) => {
    const { value } = e.target;
    setBotCreatorData((prev) => ({ ...prev, bot_name: value }));
  };

  // const handleSubmitFormOptions = () => {
  //   switch
  // }
  
  const submitForm = async () => {
    switch (true) {
      // No first message defined
      case (!firstMessageValue):
        enqueueSnackbar('First message is not defined. Please try again.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        return
       // if documents are added but analysis/grade prompt is not selected 
      case (markedDocuments.length > 0 && (!promptsSettings.analysisPrompt.is_selected || !promptsSettings.gradePrompt.is_selected)):
        enqueueSnackbar('Relevant Prompts is not selected Or Grade prompt is not selected. Please try again.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        return
      // if relevant answer prompt message to user field is empty and character prompt is not selected
      case (!promptsSettings.relevantPrompt.messageToUser && !promptsSettings.characterPrompt.is_selected): 
        enqueueSnackbar('Character prompt is not selected or Relevant answer meesage is not defined. Please try again.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        return
      // if character prompt is not selected and no documents are not added
      case (!promptsSettings.characterPrompt.is_selected && !markedDocuments.length): 
        enqueueSnackbar('Character prompt is not selected or Documents are not added. Please try again.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        return
      // if character prompt is not selected And anaylsis prompt includes 'unspecified' 
      // Or character prompt is selected And anaylsis prompt does not include 'unspecified'
      case (!promptsSettings.characterPrompt.is_selected && promptsSettings.analysisPrompt.content.toLowerCase().includes('unspecified') ||
        promptsSettings.characterPrompt.is_selected && !promptsSettings.analysisPrompt.content.toLowerCase().includes('unspecified')): 
        enqueueSnackbar('Character prompt is not selected OR \'unspecified\' is not included in anaylsis prompt. Please try again.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
        return
    }

    const promptsSettingsFiltered = filterInnerObjectByKey(promptsSettings);

    function validatePrompts(prompts) {
      // Iterate over each entry in the prompts object
      for (const [key, value] of Object.entries(prompts)) {
        // Check if the prompt has the 'is_selected' property and it's set to false
        if (value.hasOwnProperty('is_selected') && !value.is_selected) {
          continue; // Skip the validation for this prompt as it's not chosen
        }
        if (value.hasOwnProperty('isConnectToDocs') && chosenDocs.length === 0) {
          continue; // Skip the validation for this prompt irrelevant
        }
        // Ensure that the content is not empty
        if (!value.content || value.content.trim() === '') {
          // If the content is empty, return a validation error
          return { isValid: false, key };
        }
      }
      // If all prompts pass the validation, return true
      return { isValid: true, key: null };
    }
    const response = validatePrompts(promptsSettingsFiltered);

    if (!response.isValid) {
      alert(`make sure all fields are filled 
      problem in : ${response.key}
      `);
      return;
    }

    // Here this function updates the bot data in the database because it
    // already exist, because were in an already existing bot manage page
    await createOrUpdateBot({
      userId: user.id,
      botId: botId,
      firstMessageValue: firstMessageValue,
      promptsSettingsFiltered: promptsSettingsFiltered,
      toolKitSettings: toolKitSettings,
      endMessageValue: endMessageValue,
      errorMessageValue: errorMessageValue,
      additionalLinksTitle: additionalLinksTitle,
      chosenDocs: chosenDocs,
      botData: {
        bot_name: botCreatorData.bot_name,
        bot_description: botCreatorData.description,
        bot_audience: botCreatorData.bot_audience,
      },
    });
    await getAllBotsUser();
    const newTree = setFilesMark(entireTree.files, entireTree.subfolders, []);
    setEntireTree(newTree);
    setIsOpenBotCreator(false);
  };
  const handleCloseDocsModal = async () => {
    setOpenDocsModal(false);
    setPath([]);
    setActiveFolder('');
  };

  const handleCloseConverstionModel = async () => {
    setOpenConversionModal(false);
  };
  function filterInnerObjectByKey(originalObjs) {
    // Define the keys to retain in the originalObj object
    const keysToRetain = [
      'model',
      'vectors',
      'inputConversationLanguage',
      'inputVectorsLanguage',
      'content',
      'is_selected',
      'messageToUser',
      'isAnswerBasedOnBestGrade',
      'isConnectToDocs',
    ];

    // Initialize a new object to store the filtered properties
    const filteredPrompts = {};

    // Loop through the keysToRetain to filter the originalObj object
    Object.keys(originalObjs).forEach((originalObj) => {
      keysToRetain.forEach((key) => {
        if (originalObjs[originalObj].hasOwnProperty(key)) {
          if (!filteredPrompts[originalObj]) filteredPrompts[originalObj] = {};
          if (key === 'messageToUser') {
            // Special handling for messageToUser to only keep the content
            filteredPrompts[originalObj][key] = originalObjs[originalObj][key].content;
          } else {
            // Directly copy the value for other keys
            filteredPrompts[originalObj][key] = originalObjs[originalObj][key];
          }
        }
      });
    });

    return filteredPrompts;
  }

  const setPromptInsideValue = (value, prompt, key, insideKey) => {
    if (key === 'vectors') {
      value = +value;
      if (insideKey === 'vectorsNumber' && value > 10) value = 10;
      if (value < 1) value = 1;
      if (insideKey === 'passingGrade' && value > 100) value = 100;
    }
    setPromptsSettings((prevSettings) => ({
      ...prevSettings,
      [prompt]: {
        ...prevSettings[prompt],
        [key]: { ...prevSettings[prompt][key], [insideKey]: value },
      },
    }));
  };

  const closeBotCreator = () => {
    const newTree = setFilesMark(entireTree.files, entireTree.subfolders, []);
    setEntireTree(newTree);
    setIsOpenBotCreator(false);
  };

  const toggleEdit = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  if (!isDoneLoading) return <></>;

  return (
    <>
      {openDocsModal && (
        <DocsModal
          handleClose={handleCloseDocsModal}
          setChosenDocs={setChosenDocs}
          onClose={handleCloseDocsModal}
        />
      )}
      {openConversionModal && (
        <ConversionModal
          handleClose={handleCloseConverstionModel}
          onClose={handleCloseConverstionModel}
          botId={botId}
        />
      )}
      <div className="full-page-container">
        <div className="bot-page">
          <div className="bot-creator-page">
            <div className="navigation-documents-container">
              <div className="location-container">
                {/* <img src={backBtn} alt="back" onClick={closeBotCreator} /> */}
                {/* <backButton onClick={closeBotCreator} alt="back" /> */}
                <BackBtn onClick={closeBotCreator} />
                <p>Assistant / Manage - {botCreatorData.bot_name}</p>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                <button
                  onClick={() => setOpenConversionModal(true)}
                  style={{
                    backgroundColor: openConversionModal ? 'var(--main-clr)' : 'transparent',
                    color: openConversionModal ? 'var(--white-clr)' : 'var(--secondary-clr)',
                  }}
                >
                  Conversion Table
                </button>
                <button
                  onClick={() => setOpenDocsModal(true)}
                  style={{
                    backgroundColor: openDocsModal ? 'var(--main-clr)' : 'transparent',
                    color: openDocsModal ? 'var(--white-clr)' : 'var(--secondary-clr)',
                  }}
                >
                  Documents
                  {markedDocuments && markedDocuments.length > 0 ? `(${markedDocuments.length})` : ''}
                </button>
              </div>
            </div>

            <div className="prompts-container">
              <div className="first-message-container prompt-container">
                <div className="edit-bot-name">
                  <div className="bot-select-container">
                    <DisableSelectBotName />
                    <h6>Bot name</h6>
                  </div>

                  <textarea
                    name="edit-bot-name"
                    value={botCreatorData.bot_name}
                    onChange={handleBotNameChange}
                    placeholder={botCreatorData.bot_name}
                    style={{ height: '40px' }}
                  />
                </div>

                <div
                  style={{
                    // width: '1020px',
                    height: '1.2px',
                    backgroundColor: 'var(--break-line-clr)',
                    marginTop: '20px',
                    marginBottom: '20px',
                  }}
                />

                <div className="message-title-container">
                  <div className="bot-select-container">
                    <DisableSelectBotName />
                    <div>
                      <h6>First Message</h6>
                      <p>The message that will open the conversation with the user</p>
                    </div>
                  </div>
                  {firstMessageIsOpen ? (
                    <CollapseIcon
                      style={{ width: '24px', height: '24px' }}
                      onClick={() => setFirstMessageIsOpen((prev) => !prev)}
                    />
                  ) : (
                    <ExpandIcon
                      style={{ width: '24px', height: '24px' }}
                      onClick={() => setFirstMessageIsOpen((prev) => !prev)}
                    />
                  )}
                </div>
                {firstMessageIsOpen && (
                  <textarea
                    name="first-message"
                    value={firstMessageValue}
                    onChange={handleFirstMessageChange}
                    placeholder="Hi, how can i help..."
                  />
                )}
              </div>

              {Object.entries(promptsSettings).map(([key, prompt]) => (
                <>
                  {key === 'hackPrompt' && (
                    <>
                      <div className="end-message-container prompt-container">
                        <div className="message-title-container">
                          <div className="title-and-image-select">
                            {endMessageValue.is_selected !== undefined && (
                              <>
                                {endMessageValue.is_selected ? (
                                  <OptionSelected
                                    style={{ width: '32px', height: '32px' }}
                                    onClick={() =>
                                      setEndMessageValue((prev) => ({
                                        ...prev,
                                        is_selected: !prev.is_selected,
                                      }))
                                    }
                                  />
                                ) : (
                                  <OptionUnSelected
                                    style={{ width: '32px', height: '32px' }}
                                    onClick={() =>
                                      setEndMessageValue((prev) => ({
                                        ...prev,
                                        is_selected: !prev.is_selected,
                                      }))
                                    }
                                  />
                                )}
                              </>
                            )}
                            <h6>
                              End Of Conversation Message
                              <p>
                                You can limit the number of messages that the user will send, when the
                                message quota is reached, this message will be sent
                              </p>
                            </h6>
                          </div>
                          {endMessageValue.isOpen ? (
                            <CollapseIcon
                              style={{ width: '24px', height: '24px' }}
                              onClick={() =>
                                setEndMessageValue((prev) => ({
                                  ...prev,
                                  isOpen: !prev.isOpen,
                                }))
                              }
                            />
                          ) : (
                            <ExpandIcon
                              style={{ width: '24px', height: '24px' }}
                              onClick={() =>
                                setEndMessageValue((prev) => ({
                                  ...prev,
                                  isOpen: !prev.isOpen,
                                }))
                              }
                            />
                          )}
                        </div>
                        {endMessageValue.isOpen && (
                          <>
                            <textarea
                              name="end-message"
                              value={endMessageValue.content}
                              onChange={handleEndMessageChange}
                              placeholder="I was happy to assist you, if you would like to ask more questions please refresh the conversation"
                            />

                            <div className="model-options">
                              <div className="toggle-answer-container">

                                <button
                                  className={`option ${
                                    endMessageValue.isMessageLimitation ? 'selected' : ''
                                  }`}
                                  onClick={() =>
                                    setEndMessageValue((prev) => ({
                                      ...prev,
                                      isMessageLimitation: !prev.isMessageLimitation,
                                    }))
                                  }
                                >
                                  Message limitation
                                </button>
                                <button
                                 className={`option ${
                                  endMessageValue.isTimeout ? 'selected' : ''
                                }`}
                                  onClick={() =>
                                    setEndMessageValue((prev) => ({
                                      ...prev,
                                      isTimeout: !prev.isTimeout,
                                    }))
                                  }
                                >
                                  Time out
                                </button>
                              </div>

                              {endMessageValue.isMessageLimitation && (
                                <div className="vectors-container">
                                  <ScopeIcon className="icon-clr" />
                                  <span style={{ marginRight: '10px' }}>Conversation length</span>
                                  <div className="input-container">
                                    <MinusIcon
                                      onClick={() => {
                                        if (endMessageValue.maxLength > 1)
                                          setEndMessageValue((prev) => ({
                                            ...prev,
                                            maxLength: prev.maxLength - 1,
                                          }));
                                      }}
                                      className="icon-clr"
                                    />
                                    <p className="number-container">{endMessageValue.maxLength}</p>
                                    <PlusIcon
                                      onClick={() => {
                                        if (endMessageValue.maxLength < 15)
                                          setEndMessageValue((prev) => ({
                                            ...prev,
                                            maxLength: prev.maxLength + 1,
                                          }));
                                      }}
                                      className="icon-clr"
                                    />
                                  </div>
                                </div>
                              )}
                              {endMessageValue.isTimeout && (
                                <div className="vectors-container">
                                  <TimeDurationIcon className="icon-clr" />
                                  <span style={{ marginRight: '10px' }}>Time out Length</span>
                                  <div className="input-container">
                                    <MinusIcon
                                      onClick={() => {
                                        if (endMessageValue.timeLimited > 1)
                                          setEndMessageValue((prev) => ({
                                            ...prev,
                                            timeLimited: prev.timeLimited - 1,
                                          }));
                                      }}
                                      className="icon-clr"
                                    />
                                    <p className="number-container">{endMessageValue.timeLimited}</p>{' '}
                                    <PlusIcon
                                      onClick={() => {
                                        if (endMessageValue.timeLimited < 15)
                                          setEndMessageValue((prev) => ({
                                            ...prev,
                                            timeLimited: prev.timeLimited + 1,
                                          }));
                                      }}
                                      className="icon-clr"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <div className="end-message-container prompt-container">
                        <div className="message-title-container">
                          <div className="title-and-image-select">
                            {errorMessageValue.is_selected ? (
                              <OptionSelected
                                style={{ width: '32px', height: '32px' }}
                                onClick={() =>
                                  setErrorMessageValue((prev) => ({
                                    ...prev,
                                    is_selected: !prev.is_selected,
                                  }))
                                }
                              />
                            ) : (
                              <OptionUnSelected
                                style={{ width: '32px', height: '32px' }}
                                onClick={() =>
                                  setErrorMessageValue((prev) => ({
                                    ...prev,
                                    is_selected: !prev.is_selected,
                                  }))
                                }
                              />
                            )}

                            <h6>
                              Problem in receiving reply Message
                              <p>
                                when there is a slowdown in receiving answers from the server, the
                                recommendation is to refresh the chat
                              </p>
                            </h6>
                          </div>

                          {errorMessageValue.isOpen ? (
                            <CollapseIcon
                              style={{ width: '24px', height: '24px' }}
                              onClick={() =>
                                setErrorMessageValue((prev) => ({
                                  ...prev,
                                  isOpen: !prev.isOpen,
                                }))
                              }
                            />
                          ) : (
                            <ExpandIcon
                              style={{ width: '24px', height: '24px' }}
                              onClick={() =>
                                setErrorMessageValue((prev) => ({
                                  ...prev,
                                  isOpen: !prev.isOpen,
                                }))
                              }
                            />
                          )}
                        </div>
                        {errorMessageValue.isOpen && (
                          <>
                            <textarea
                              name="error-message"
                              value={errorMessageValue.content}
                              onChange={handleErrorMessageChange}
                              placeholder={errorMessageValue.default_message}
                            />
                          </>
                        )}
                      </div>
                      <div
                        className="additional-links-title-container prompt-container"
                        style={{
                          opacity: toolKitSettings.questions_selected.includes('Web page link')
                            ? 1
                            : 0.5,
                        }}
                      >
                        <div className="message-title-container">
                          <div className="title-and-image-select">
                            {additionalLinksTitle.is_selected !== undefined && (
                              <>
                                {additionalLinksTitle.is_selected ? (
                                  <OptionSelected
                                    style={{ width: '32px', height: '32px' }}
                                    onClick={handleAdditionalLinksSelect}
                                  />
                                ) : (
                                  <OptionUnSelected
                                    style={{ width: '32px', height: '32px' }}
                                    onClick={handleAdditionalLinksSelect}
                                  />
                                )}
                              </>
                            )}
                            <h6>
                              Web pages link title
                              <p>
                                After setting up the links on the vectors page, define an appropriate
                                title that will describe the redirection
                              </p>
                            </h6>
                          </div>
                          {additionalLinksTitle.isOpen ? (
                            <CollapseIcon
                              style={{ width: '24px', height: '24px' }}
                              onClick={handleAdditionalLinksOpen}
                            />
                          ) : (
                            <ExpandIcon
                              style={{ width: '24px', height: '24px' }}
                              onClick={handleAdditionalLinksOpen}
                            />
                          )}
                        </div>
                        {additionalLinksTitle.isOpen && (
                          <>
                            <textarea
                              name="additional-links-title"
                              value={additionalLinksTitle.content}
                              onChange={handleAdditionalLinksTitleChange}
                              placeholder="read more..."
                            />
                          </>
                        )}
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      // width: '1020px',
                      height: '1.2px',
                      backgroundColor: 'var(--break-line-clr)',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  />
                  <PromptContainer
                    setPromptInsideValue={setPromptInsideValue}
                    key={key}
                    promptKey={key}
                    prompt={prompt}
                    updatePromptSetting={updatePromptSetting}
                    chosenDocs={chosenDocs}
                  />{' '}
                </>
              ))}
            </div>
          </div>{' '}
        </div>
      </div>
      <div className="footer">
        <button onClick={submitForm}>Submit</button>
      </div>
    </>
  );
};

export default BotManage;
